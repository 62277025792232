import React from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

import Global from '../Global';

import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Email from '@material-ui/icons/Email';
import Language from '@material-ui/icons/Language';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Phone, WhatsApp } from '@material-ui/icons';


class Expositor extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
        estadoVista: 'pendiente',
        expositor: {}
    }
  }

  componentDidMount(){

    axios.get(Global.url_api + 'expo/expositor/' + this.props.match.params.id, { headers: { Authorization: localStorage.getItem("token") } })
      .then(res => {
          
        let expositor = res.data.expositor;

        let estadoVistaTemp = 'pendiente';
        if( expositor ){
          estadoVistaTemp = 'hay_resultados';
        }else{
          estadoVistaTemp = 'sin_resultados';
        }

        this.setState({
            estadoVista: estadoVistaTemp,
            expositor: expositor
        });


      })
      .catch((error) => {
          console.log(error);
          this.setState({
              estadoVista: 'error',
              expositor: {}
          });
      });
  }
  
  render() {

    return(
      <Container maxWidth="sm" style={{'textAlign':'center'}}>
        <div style={{'marginTop':'70px'}}>
      {
        this.state.estadoVista === 'pendiente' && 
        <div style={{padding: '15px'}}><CircularProgress style={{'color': 'black'}} /></div>
      }
      {
        this.state.estadoVista === 'hay_resultados' && 
          <React.Fragment>
              <br />
              <Paper elevation={2}>
              <List component="nav" aria-label="main mailbox folders" style={{'background':'#fff'}}>
                <ListItem button component={NavLink} to={"/imagen-expositor/" + this.state.expositor.expo.planimetria.split('/')[5]}>
                      <Grid container spacing={2}>
                        <Grid item xs={11}>
                          PABELLÓN <b>{this.state.expositor.expo.nro_pabellon}</b> / STAND <b>{this.state.expositor.expo.nro_stand}</b>
                        </Grid>
                        <Grid item xs={1}>
                          <ChevronRight />
                        </Grid>
                      </Grid> 
                  </ListItem>
                </List>
              </Paper>
    
                <br />
                <Paper elevation={2}>
    
                  <Grid container spacing={2}>
                    {
                      this.state.expositor.logo && 
                      <React.Fragment>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                          <img width="100%" src={this.state.expositor.logo} alt={this.state.expositor.nombre} />
                        </Grid>
                        <Grid item xs={4}></Grid>
                      </React.Fragment>
                    }
                    
                    {
                      this.state.expositor.expo.portada && 
                      <Grid item xs={12}>
                        <img width="100%" src={this.state.expositor.expo.portada} alt={this.state.expositor.nombre} />
                      </Grid>
                    }
                    
                  </Grid>
    
                </Paper>

                {
                  this.state.expositor.expo.descripcion && 
                  <React.Fragment>
                    <br />
                    <Paper elevation={2}>
                      <div style={{padding:'5px 0px'}}>
                        <h3>Información</h3>
                        <div dangerouslySetInnerHTML={{__html: this.state.expositor.expo.descripcion}} />
                      </div>
                    </Paper>
                  </React.Fragment>
                }
                
    
                <br />
                <Paper elevation={2}>
                  <List component="nav" aria-label="main mailbox folders" style={{'background':'#fff'}}>
                    
                  {
                      this.state.expositor.tel_fijo && 
                      <React.Fragment>
                        <ListItem button component="a" href={"tel:" + this.state.expositor.tel_fijo} target="_blank">
                            <ListItemIcon>
                                <Phone />
                            </ListItemIcon>
                            <ListItemText primary={this.state.expositor.tel_fijo} />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    }

                    {
                      this.state.expositor.tel_whatsapp && 
                      <React.Fragment>
                        <ListItem button component="a" href={"https://wa.me/" + this.state.expositor.tel_whatsapp.replace("+","") } target="_blank">
                            <ListItemIcon>
                                <WhatsApp />
                            </ListItemIcon>
                            <ListItemText primary={this.state.expositor.tel_whatsapp} />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    }

                    {
                      this.state.expositor.email_comercial && 
                      <React.Fragment>
                        <ListItem button component="a" href={"mailto:" + this.state.expositor.email_comercial} target="_blank">
                            <ListItemIcon>
                                <Email />
                            </ListItemIcon>
                            <ListItemText primary={this.state.expositor.email_comercial} />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    }
                    
                    {
                      this.state.expositor.sitio_web && 
                      <React.Fragment>
                          <ListItem button component="a" href={this.state.expositor.sitio_web} target="_blank">
                              <ListItemIcon>
                                  <Language />
                              </ListItemIcon>
                              <ListItemText primary={this.state.expositor.sitio_web} />
                          </ListItem>
                          <Divider />
                      </React.Fragment>
                    }

                    {
                      this.state.expositor.facebook && 
                      <React.Fragment>
                        <ListItem button component="a" href={"https://www.facebook.com/" + this.state.expositor.facebook} target="_blank">
                          <ListItemIcon>
                              <Facebook />
                          </ListItemIcon>
                          <ListItemText primary={this.state.expositor.facebook} />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    }
                    
                    {
                      this.state.expositor.instagram && 
                      <React.Fragment>
                        <ListItem button component="a" href={"https://www.instagram.com/" + this.state.expositor.instagram} target="_blank">
                            <ListItemIcon>
                                <Instagram />
                            </ListItemIcon>
                            <ListItemText primary={this.state.expositor.instagram} />
                        </ListItem>
                      </React.Fragment>
                    }
                    
                  </List>
                </Paper>
                <br />
            </React.Fragment>
        }
        {
          this.state.estadoVista === 'error' && 
          <div>
            <p style={{padding: '15px 5px'}}>
              ¡Erorr!
              <br />
              Parece que ocurrió un erorr al intentar mostrar el expositor.
              <br />
              Si el problema persiste, por favor contactanos.                    
            </p>
          </div>
        }
        </div>
      </Container>
    )
      
  }
}

export default Expositor;
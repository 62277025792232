import React, { Component } from 'react';

//Routers
import Router from './Router';
import Login from './components/vistas/Login';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userStatus: ''
    }

  }

  componentDidMount(){
    if( localStorage.getItem("token") && localStorage.getItem("token").length > 0 ){      
      this.setState({
        userStatus: 'loggedin'
      });
    }
  }

  changeUserStatus = (status) => {
    this.setState({
      userStatus: status
    })
  }


  render() {
    
    if (this.state.userStatus === 'loggedin') {

      return (
        <div className="App">
          <Router changeUserStatus={this.changeUserStatus} />
        </div>
      );

    }else{

      return (
        <div className="App">
          <Login changeUserStatus={this.changeUserStatus} state={this.state} />
        </div>
      );

    }
    
  }


}

export default App;

import { React, useState } from 'react';


import '../../assets/css/loggedin.css';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


//iconos
import ExpandMore from '@material-ui/icons/ExpandMore';

export default function ExpositoresFiltros(props) {

  const [pabellon1, setPabellon1] = useState(props.searchPabellones[0]);
  const [pabellon2, setPabellon2] = useState(props.searchPabellones[1]);
  const [pabellon3, setPabellon3] = useState(props.searchPabellones[2]);
  const [pabellon4, setPabellon4] = useState(props.searchPabellones[3]);
  
  const [searchStand, setSearchStand] = useState(props.searchStand);

  const handleAlicaFiltrosPabellon = () => {
    props.searchPabellonesAplica([pabellon1, pabellon2, pabellon3, pabellon4])
  }

  const handleAlicaFiltrosStand = () => {
    props.searchStandAplica(searchStand)
  }
  
  return (
    <div>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <h3 style={{marginTop:'0', marginBottom:'0'}}>Por Pabellón</h3>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                <Box style={{marginBottom:'25px'}}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={pabellon1} style={{ color: '#3C3C3B' }} onChange={e => setPabellon1(e.target.checked)} />} label="Pabellón 1" />
                        <FormControlLabel control={<Checkbox checked={pabellon2} style={{ color: '#3C3C3B' }} onChange={e => setPabellon2(e.target.checked)} />} label="Pabellón 2" />
                        <FormControlLabel control={<Checkbox checked={pabellon3} style={{ color: '#3C3C3B' }} onChange={e => setPabellon3(e.target.checked)} />} label="Pabellón 3" />
                        <FormControlLabel control={<Checkbox checked={pabellon4} style={{ color: '#3C3C3B' }} onChange={e => setPabellon4(e.target.checked)} />} label="Pabellón 4" />
                    </FormGroup>
                </Box>
                <Box style={{marginBottom:'25px'}}>
                    <Button variant="contained" onClick={handleAlicaFiltrosPabellon}>Aplicar Filtro</Button>
                </Box>
                </div>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <h3 style={{marginTop:'0', marginBottom:'0'}}>Por Stand</h3>
            </AccordionSummary>
            <AccordionDetails>
            <FormGroup>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField onChange={e => setSearchStand(e.target.value)} value={searchStand} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} helperText="N° Stand" />
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" onClick={handleAlicaFiltrosStand}>Aplicar</Button>
                    </Grid>
                </Grid>
            </FormGroup>
            </AccordionDetails>
        </Accordion>
    </div>
  );
}
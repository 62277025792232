import { React, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Navbar from './components/elementos/Navbar';

//Componentes Vistas
import Home from './components/vistas/Home';
import Nosotros from './components/vistas/Nosotros';
import Planimetria from './components/vistas/Planimetria';
import Expositores from './components/vistas/Expositores';
import Expositor from './components/vistas/Expositor';


import Imagen from './components/vistas/Imagen';
import ImagenExpositor from './components/vistas/ImagenExpositor';

export default function Router() {
  
  const [searchTexto, setSearchTexto] = useState('');
  const [searchStand, setSearchStand] = useState('');
  const [searchPabellones, setSearchPabellones] = useState([true,true,true,true]);


  const handleSearchTextoChange = (valor) => {
    setSearchTexto(valor);
  }

  const handleSearchStandSubmit = (valor) => {
    setSearchStand(valor);
  }

  const handleSearchPabellones = (valor) => {
    setSearchPabellones(valor);
  }

  return(
    <BrowserRouter>
      <Navbar 
        searchTextoChange={handleSearchTextoChange}
      />
      <Switch>

        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/nosotros" component={Nosotros} />
        <Route exact path="/planimetria" component={Planimetria} />
        <Route exact path="/expositores" component={() => <Expositores 
          searchTexto={searchTexto} 
          searchPabellones={searchPabellones} 
          searchStand={searchStand} 
          searchPabellonesAplica={handleSearchPabellones}
          searchStandAplica={handleSearchStandSubmit}
        />} />
        <Route exact path="/expositor/:id" component={Expositor} />

        <Route exact path="/imagen/:ruta" component={Imagen} />
        <Route exact path="/imagen-expositor/:ruta" component={ImagenExpositor} />
      </Switch>

    </BrowserRouter>
  );
     

  
}

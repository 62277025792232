import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Global from '../Global';

import '../../assets/css/loggedin.css';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Pagination from '@material-ui/lab/Pagination';


import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronRight from '@material-ui/icons/ChevronRight';


import ExpositoresFiltros from './ExpositoresFiltros';


export default function Expositores(props) {

  const [estadoVista, setEstadoVista] = useState("pendiente");
  const [expositores, setExpositores] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const handleChangePage = (event, value) => {
    window.scrollTo(0, 0);
    setEstadoVista('pendiente');
    setPage(value);
  }

  useEffect(() => {

    //if( props.searchTexto.length >= 3 || props.searchPabellones != [true, true, true, true] || props.searchStand ){

      let data = {
        nombre: props.searchTexto,
        pabellones: props.searchPabellones,
        stand: props.searchStand,
        page: page
      };
  
      axios.post(Global.url_api + 'expo/expositores', data, { headers: { Authorization: localStorage.getItem("token") } })
        .then(res => {
            
          let expositoresTemp = res.data.expositores;
  
          let estadoVistaTemp = 'pendiente';
          if( expositoresTemp.length > 0 ){
            estadoVistaTemp = 'hay_resultados';
          }else{
            estadoVistaTemp = 'sin_resultados';
          }
          
          setExpositores(expositoresTemp);
          setEstadoVista(estadoVistaTemp);
          setPages(res.data.pages);
  
        })
        .catch((error) => {
          console.log(error);
          setExpositores([]);
          setEstadoVista('error');
        });

  }, [props.searchTexto, page]);

  
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        marginTop: theme.spacing(2),
        justifyContent:"center",
        display:'flex'
      }
    },
    pagination: {
      alignItems: 'center',
      justify: 'center',
    }
  }));
  const classes = useStyles();
  
  
  return (
    <div style={{'marginTop':'70px'}}>
      
      <Container maxWidth="sm" style={{'textAlign':'center'}}>
        
        <ExpositoresFiltros 
          searchPabellones={props.searchPabellones} 
          searchStand={props.searchStand} 
          searchPabellonesAplica={props.searchPabellonesAplica}
          searchStandAplica={props.searchStandAplica}        
        />

        <h2 style={{marginBottom: '0'}}>EXPOSITORES</h2>
        <p style={{marginTop: '0', color: 'rgb(168, 168, 168)'}}>Resultados de búsqueda</p>
        <Container maxWidth="sm" style={{backgroundColor: '#fff'}}>
          {
            estadoVista === 'pendiente' && 
            <div style={{padding: '15px'}}><CircularProgress style={{'color': 'black'}} /></div>
          }
          {
            estadoVista === 'hay_resultados' && 
            expositores.map(function(expositor, index){
              return(
                <NavLink style={{textDecoration: 'none'}} to={'/expositor/' + expositor._id} key={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <img style={{ width: '100%' }} alt={expositor.nombre} src={expositor.logo}  />
                    </Grid>
                    <Grid item xs={7} align="left">
                      <h5 style={{margin: '2px', marginTop: '10px', color: '#000'}}>{expositor.nombre}</h5>
                      <p style={{margin: '2px', color: 'rgb(168, 168, 168)'}}>Pabellón: <span style={{color: '#000'}}>{expositor.expo.nro_pabellon}</span> / Stand: <span style={{color: '#000'}}>{expositor.expo.nro_stand}</span></p>
                    </Grid>
                    <Grid item xs={1} style={{paddingTop: '28px', color: '#000'}}>
                      <ChevronRight />
                    </Grid>
                  </Grid>
                  <Divider  />
                </NavLink>
              )
            })
          }
          {
            estadoVista === 'sin_resultados' && 
            <div>
              <p style={{padding: '15px 5px'}}>
                No se encontraron resultados para los filtros aplicados.
                <br />Intenta modificando los filtros.
              </p>
            </div>
          }
          {
            estadoVista === 'faltan_caracteres' && 
            <div>
              <p style={{padding: '15px 5px'}}>
                Para buscar, debes ingresar al menos 3 caracteres.
                <br />
              </p>
            </div>
          }
          {
            estadoVista === 'error' && 
            <div>
              <p style={{padding: '15px 5px'}}>
                ¡Erorr!
                <br />
                Parece que ocurrió un erorr al intentar mostrar los expositores.
                <br />
                Si el problema persiste, por favor contactanos.                    
              </p>
            </div>
          }
        </Container>
        
        <div style={{padding: '15px 5px'}} className={classes.root}>
          <Pagination className={classes.pagination} count={pages} defaultPage={page} onChange={handleChangePage} variant="outlined" />
        </div>

      </Container>
      
    </div>
  );
}


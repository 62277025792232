import React from 'react';
import axios from 'axios';
import Global from '../Global';

import '../../assets/css/estilos.css';

//Material
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import CircularProgress from '@material-ui/core/CircularProgress';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            estadoVista: 'pendiente'
        }

        this.emailRef = React.createRef();
        this.documentoRef = React.createRef();

    }

    changeState = (e) => {

        this.setState({
            estadoVista: 'pendiente',
            mensajeErorr: ''
        });

    }

    enviarForm = (e) => {       
        e.preventDefault();

        let data = {
            email: this.emailRef.current.value,
            dni: this.documentoRef.current.value
        }

        this.setState({
            estadoVista: 'loading'
        });

        axios.post(Global.url_api + 'expo/login', data)
            .then(res => {
                
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("nombre", res.data.usuario.nombre);
                localStorage.setItem("apellido", res.data.usuario.apellido);
                localStorage.setItem("email", res.data.usuario.email);
                

                this.props.changeUserStatus('loggedin');

            })
            .catch((error) => {
                this.setState({
                    estadoVista: 'pendiente',
                    mensajeErorr: error.response.data.message
                });
        });

        //erorr
        
        
    }


    render() {

        return(
            <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <p align="center"><img width="90%" alt="Cafira" src="https://cafira.sfo2.digitaloceanspaces.com/expo/logo-expo-cafira.png" /></p>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} className="paper">
                            {
                                this.state.estadoVista === 'pendiente' && 
                                <form onSubmit={this.enviarForm}>
                                    <h3>INGRESO</h3>
                                    <TextField 
                                        type="email" 
                                        fullWidth 
                                        helperText="Email que usaste para registrarte" 
                                        id="email" 
                                        label="Email" 
                                        onChange={this.changeState}
                                        inputRef={this.emailRef}
                                    />
                                    <TextField 
                                        type="tel" 
                                        fullWidth 
                                        helperText="Documento que usaste para registrarte" 
                                        id="documento" 
                                        label="Documento" 
                                        onChange={this.changeState}
                                        inputRef={this.documentoRef}
                                    />
                                    <h3 className="error">{this.state.mensajeErorr}</h3>
                                    <p><Button type="submit" variant="outlined" color="default">
                                        Continuar
                                    </Button></p>
                                </form>
                            }
                            {
                                this.state.estadoVista === 'loading' && 
                                <div><CircularProgress style={{'color': 'black'}} /></div>   
                            }
                        </Paper>
                    </Grid>
                </Grid >
            </Container>
        );

    }

}

export default Login;
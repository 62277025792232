import React from 'react';
import { NavLink } from 'react-router-dom';

import '../../assets/css/loggedin.css';

import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Map from '@material-ui/icons/Map';

export default function Home() {
  
  return (
    <div style={{'marginTop':'70px'}}>
      
      <Container maxWidth="sm" style={{'textAlign':'center'}}>
        <img width="90%" style={{'maxWidth':'300px','margin':'10px'}} alt="Cafira" src="https://cafira.sfo2.digitaloceanspaces.com/expo/logo-expo-cafira.png" />
        <Divider />
        <h2>PLANIMETRÍA</h2>
        <List component="nav" aria-label="main mailbox folders" style={{'background':'#fff'}}>
            <ListItem button component={NavLink} to="imagen/completo2.jpg">
                <ListItemIcon>
                    <Map />
                </ListItemIcon>
                <ListItemText primary="Plano Completo" />
            </ListItem>
            <Divider />
            <ListItem button component={NavLink} to="imagen/pabellon2-1.png">
                <ListItemIcon>
                    <Map />
                </ListItemIcon>
                <ListItemText primary="Pabellón 1" />
            </ListItem>
            <Divider />
            <ListItem button component={NavLink} to="imagen/pabellon2-2.png">
                <ListItemIcon>
                    <Map />
                </ListItemIcon>
                <ListItemText primary="Pabellón 2" />
            </ListItem>
            <Divider />
            <ListItem button component={NavLink} to="imagen/pabellon2-3.png">
                <ListItemIcon>
                    <Map />
                </ListItemIcon>
                <ListItemText primary="Pabellón 3" />
            </ListItem>
            <Divider />
            <ListItem button component={NavLink} to="imagen/pabellon2-4.png">
                <ListItemIcon>
                    <Map />
                </ListItemIcon>
                <ListItemText primary="Pabellón 4" />
            </ListItem>
            <Divider />
        </List>

      </Container>
      
    </div>
  );
}
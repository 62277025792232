import React from 'react';
import { useParams } from 'react-router-dom';

import '../../assets/css/loggedin.css';

import Container from '@material-ui/core/Container';

export default function Imagen(props) {

    const params = useParams();
  
    return (
    <div style={{'marginTop':'70px'}}>
        
        <Container maxWidth="sm" style={{'textAlign':'center'}}>
        <img width="100%" alt="Plano" src={ "https://cafira.sfo2.digitaloceanspaces.com/expo/planos/" + params.ruta } />
        
        </Container>
        
    </div>
    );
}
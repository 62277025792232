import React from 'react';
import { NavLink } from 'react-router-dom';

import '../../assets/css/loggedin.css';

import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

//iconos
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Map from '@material-ui/icons/Map';
import Info from '@material-ui/icons/Info';
import Contacts from '@material-ui/icons/Contacts';
import DirectionsCar from '@material-ui/icons/DirectionsCar';

export default function Home() {
  
  return (
    <div style={{'marginTop':'70px'}}>
      
      <Container maxWidth="sm" style={{'textAlign':'center'}}>
        <img width="90%" style={{'maxWidth':'300px','margin':'10px'}} alt="Cafira" src="https://cafira.sfo2.digitaloceanspaces.com/expo/logo-expo-cafira.png" />
        <Divider />
        <h2>BIENVENIDOS</h2>
        <List style={{backgroundColor:'#fff'}}>
            <ListItem button component={NavLink} to="/expositores">
              <ListItemIcon><Contacts /></ListItemIcon>
              <ListItemText primary="Expositores" />
            </ListItem>
            <ListItem button component={NavLink} to="/planimetria">
              <ListItemIcon><Map /></ListItemIcon>
              <ListItemText primary="Planimetría" />
            </ListItem>
        </List>
        <Divider />
        <List style={{backgroundColor:'#fff'}}>
            <ListItem button component="a" href="https://maps.app.goo.gl/xE3n5oKNUfvn4T31A" target="_blank">
              <ListItemIcon><DirectionsCar /></ListItemIcon>
              <ListItemText primary="Cómo Llegar" />
            </ListItem>
            <ListItem button component={NavLink} to="/nosotros">
              <ListItemIcon><Info /></ListItemIcon>
              <ListItemText primary="Nosotros" />
            </ListItem>
        </List>
      </Container>
      
    </div>
  );
}
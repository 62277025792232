import React from 'react';

import '../../assets/css/loggedin.css';

import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Email from '@material-ui/icons/Email';
import Language from '@material-ui/icons/Language';
import Facebook from '@material-ui/icons/Facebook';
import Twitter from '@material-ui/icons/Twitter';
import Instagram from '@material-ui/icons/Instagram';


export default function Nosotros() {
  
  return (
    <div style={{'marginTop':'70px'}}>
      
      <Container maxWidth="sm" style={{'textAlign':'center'}}>
        <img width="90%" style={{'maxWidth':'300px','margin':'10px'}} alt="Cafira" src="https://cafira.sfo2.digitaloceanspaces.com/expo/logo-expo-cafira.png" />
        <Divider />
        <h2>NUESTRAS REDES</h2>
        <List component="nav" aria-label="main mailbox folders" style={{'background':'#fff'}}>
            <ListItem button component="a" href="mailto:info@cafiramarket.com" target="_blank">
                <ListItemIcon>
                    <Email />
                </ListItemIcon>
                <ListItemText primary="info@cafiramarket.com" />
            </ListItem>
            <Divider />
            <ListItem button component="a" href="https://cafiramarket.com" target="_blank">
                <ListItemIcon>
                    <Language />
                </ListItemIcon>
                <ListItemText primary="cafiramarket.com" />
            </ListItem>
            <Divider />
            <ListItem button component="a" href="https://www.facebook.com/cafirainnova/" target="_blank">
                <ListItemIcon>
                    <Facebook />
                </ListItemIcon>
                <ListItemText primary="cafirainnova" />
            </ListItem>
            <Divider />
            <ListItem button component="a" href="https://twitter.com/CafiraInnova" target="_blank">
                <ListItemIcon>
                    <Twitter />
                </ListItemIcon>
                <ListItemText primary="cafiraInnova" />
            </ListItem>
            <Divider />
            <ListItem button component="a" href="https://www.instagram.com/expocafira/" target="_blank">
                <ListItemIcon>
                    <Instagram />
                </ListItemIcon>
                <ListItemText primary="expocafira" />
            </ListItem>
        </List>

      </Container>


      
    </div>
  );
}